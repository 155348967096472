<template>
  <div>
    <div class="addAdmin">
      <b-form @submit.prevent="addAdmins()">
        <b-form-group>
          <multiselect class="w-50" v-model="selectedAdmins" tag-placeholder="Add this as new tag"
            placeholder="Add Admin" label="name" track-by="code" :options="options" :multiple="true" :taggable="true"
            @tag="addTag"></multiselect>
        </b-form-group>
        <b-form-group>
          <div class="d-flex justify-content-between">
            <b-button size="sm" variant="primary" type="submit" :disabled="adminBtn.disabled"
              v-if="selectedAdmins.length != 0">{{ adminBtn.text }}</b-button>
            <b-button size="sm" variant="danger" @click="$emit('adminsCreated', false)"
              :disabled="adminBtn.disabled">Cancel</b-button>
          </div>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
export default {
  props: ["editInfo", "adminList", "existingAdmins"],
  created() {
    this.facultyDetails();
  },
  data() {
    return {
      adminBtn: {
        text: "Submit",
        disabled: false,
      },
      selectedAdmins: [],
      adminObject: {},
      options: [],
    };
  },
  methods: {
    async addAdmins() {
      this.adminBtn = {
        text: "Please wait...",
        disabled: true,
      };
      let admins;
      if (this.editInfo.group == true || !this.editInfo.group == false) {
        admins = {
          institution_users: [],
        };
      }
      if (this.editInfo.group == undefined) {
        admins = {
          institution_users: [],
          maingroup_id: this.editInfo.id,
          maingrouprole_id: 1,
        };
      }

      await this.selectedAdmins.forEach((admin) => {
        admins.institution_users.push(admin.id);
      });
      let url;
      if (this.editInfo.group == true) {
        url = this.$store.getters.getAPIKey.createAdmin.replace(
          "usergroup_id",
          this.editInfo.id
        );
      } else if (!this.editInfo.group == false) {
        url = this.$store.getters.getAPIKey.createAdminSubgroup.replace(
          "usersubgroup_id",
          this.editInfo.id
        );
      } else {
        url =
          this.$store.getters.getAPIKey.mainAPI +
          `/maingroupusers`;
      }
      await this.$axios
        .post(url, admins)
        .then((response) => {
          this.$emit("adminsCreated", true);

          response;
        })
        .catch((error) => {
          error;
        });
      this.adminBtn = {
        text: "Submit",
        disabled: false,
      };
    },
    async facultyDetails() {
      this.options = [];
      this.selectedAdmins = [];

      await this.adminList.forEach((staff) => {
        this.$set(this.adminObject, staff.id, {
          name: `${staff.first_name} ${staff.last_name}`,
          id: staff.id,
          code: staff.id,
          institution_user_id: staff.id,
        });
        if (staff.enroll_status == 'active') this.options.push({
          name: `${staff.first_name} ${staff.last_name} (${staff.email})`,
          id: staff.id,
          code: staff.id,
          institution_user_id: staff.institution_user_id,
        });
      });
      if (this.existingAdmins.length != 0) {
        await this.existingAdmins.forEach((existing) => {
          let existingId = existing.institution_user_id;
          this.options.forEach((student, studentIndex) => {
            if (existingId == student.id) {
              this.options.splice(studentIndex, 1);
            }
          });
        });
      }
      this.options.sort((a, b) => a.name.localeCompare(b.name));
      this.loadAdmins = false;
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
        id: newTag,
      };
      this.options.push(tag);
      this.selectedAdmins.push(tag);
    },
  },
};
</script>
