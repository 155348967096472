<template>

  <div id="questionPaperPreview" class="" style="background-color: white;">
    <!-- header ----------------- -->
    <b-row class="">
      <b-col class="text-right">
       
        <span style="font-size: 35px !important; font-weight: 800;">{{ examSettings.slot_number }}</span>
      </b-col>
    </b-row>
    <b-row class="">
      <b-col>Register No. :...................................</b-col>
      <b-col class="text-right"> Name:........................................................</b-col>
    </b-row>
    <div class="customHeaderDiv mt-2" v-html="customHeader"></div>
    <div class="mb-3">
      <table style="width: 100%;">
        <tr>
          <td class="text-center">Time: {{ examSettings.questionPaperTime?examSettings.questionPaperTime:'_' }}
            Hrs.</td>
          <td class="text-center">{{ exam.name }}</td>
          
          <td class="text-center" v-if="examSettings.questionPaperDate != ''">Date: {{ examSettings.questionPaperDate }}</td>
          <td class="text-center">Maximum Mark: {{ examSettings.questionPaperMaximumMark }} </td>
        </tr>
      </table>
    </div>
    <!-- ------------------------------- -->
    <!-- questions --------------------- -->

    <div class="text-danger" v-if="loading"> Loading...</div>


    <table class="common-font" v-if="!loading" :class="{ 'custom-table22': true, 'no-border-cell': examSettings.enableQuestionTableBorder }">
      <tr :class="{ 'no-border-cell': examSettings.enableQuestionTableBorder }">
        <th class="text-center">No.</th>
        <th class="pr-3 text-center">Question</th>
        <th class="px-3 text-center">Mark</th>
        <th class="px-3 text-center" v-if="examSettings.enabledHeadersCo">CO</th>
        <th class="px-3 text-center" v-if="examSettings.enabledHeadersModule">ML</th>
        <th class="px-3 text-center" v-if="examSettings.enabledHeadersTaxonamy">BL</th>
        <th class="px-3 text-center" v-if="examSettings.enabledHeadersPo">PO</th>
        <th class="px-3 text-center" v-if="examSettings.enabledHeadersPso">PSO</th>
        <!-- <th v-for="header in enabledHeaders" :key="header">{{header}}</th> -->
      </tr>
      <tbody v-for="row in questionPaperTableData" :key="(row.type + '' + row.id)"
        :class="{ 'no-border-cell': examSettings.enableQuestionTableBorder }">
        <tr v-if="row.type == 'orGroup'" :class="{ 'no-border-cell': examSettings.enableQuestionTableBorder }">
          <td :colspan="row.colspan" class="mt-2 p-1 text-center text-info font-weight-bold">{{ row.text }}</td>
        </tr>
        <tr v-if="row.type == 'orGroupQuestionSeparation'" :class="{ 'no-border-cell': examSettings.enableQuestionTableBorder }">
          <td :colspan="row.colspan" class="text-center font-weight-bold">{{
            row.text
          }}</td>
        </tr>
        <tr v-if="row.type == 'section'" :class="{ 'no-border-cell': examSettings.enableQuestionTableBorder }">
          <td :colspan="row.colspan" class="mt-4 p-1 text-center font-weight-bold">{{ row.text }}</td>
        </tr>
        <tr style="page-break-inside:auto;" v-if="row.type == 'question'" :class="{ 'no-border-cell': examSettings.enableQuestionTableBorder }">
          <td>{{ row.q_no }}</td>
          <td :colspan="row.colspan != null ? row.colspan : ''">
            <span v-for="(qData, qdIndex) in row.question" :key="qdIndex" class="mr-1">
              <span class="big-font" v-if="qData.type == 'text'" v-html="latexCodeToMathLive(qData.value)"></span>
              <math-field v-if="qData.type == 'equation'" class="p-0" style="border: none;" readonly>{{
            qData.value
          }}</math-field>
              <img class="questionPaperPreviewImg" v-if="qData.type == 'image'" style=""
                :src="questionAttachments[qData.value].url">
            </span>
          </td>
          <td class="text-center big-font" v-if="(row.colspan == null)">{{ row.mark }}</td>
          <td class="text-center" v-if="row.colspan == null && examSettings.enabledHeadersCo">{{ row.co }}</td>
          <td class="text-center" v-if="row.colspan == null && examSettings.enabledHeadersModule">{{ row.module }}</td>
          <td class="text-center" v-if="row.colspan == null && examSettings.enabledHeadersTaxonamy">{{ row.taxonamy }}</td>
          <td class="text-center" v-if="row.colspan == null && examSettings.enabledHeadersPo">{{ row.po }}</td>
          <td class="text-center" v-if="row.colspan == null && examSettings.enabledHeadersPso">{{ row.pso }}</td>
        </tr>
      </tbody>
    </table>

    <!-- ------------------------------- -->
    <!-- ---------footer  -->
    <h5 class="mt-5 common-font" v-if="examSettings.footerQuestionPaperLevel">Question Paper Level: {{ taxonamyScoreProductSum }}/{{ taxonamyScoreSum
      }}={{ Math.round(taxonamyScoreProductSum / taxonamyScoreSum * 100) / 100 }}</h5>
    <h5 v-if="examSettings.enabledHeadersCo" class="mt-2 common-font" >CO: Course Outcomes</h5>
    <ul v-if="examSettings.enabledHeadersCo" class="common-font">
      <li v-for="(co, coId) in courseOutcomes" :key="coId">{{ co.code }}: {{ co.outcome }}</li>
    </ul>
    <h5 v-if="examSettings.enabledHeadersTaxonamy" class="common-font">BL: Blooms Taxonomy Level</h5>
    <p v-if="examSettings.enabledHeadersTaxonamy" class="common-font">
      <span v-for="(tx, coId) in bloomTaxonomyOptions" :key="coId" >{{ tx.value }}-{{ tx.text }},
        &nbsp;&nbsp;&nbsp;</span>
    </p>
    <h5 v-if="examSettings.enabledHeadersModule" class="common-font"> ML: Module</h5>
    <table :class="{ 'custom-table22': true,}" class="mt-5 common-font" v-if="examSettings.footerSignatureField">
      <thead>
        <tr>
          <th>Prepared by (Faculty in charge)</th>
          <th>Verified by (DQAC member)</th>
          <th>Approved by (HOD)</th>
        </tr>
        <tr>
          <td class="p-2">{{ footerData.course_admin }} </td>
          <td class="p-2"> </td>
          <td class="p-2">
            <!-- {{ footerData.maingroup_admin }}  -->
          </td>
        </tr>
      </thead>
    </table>
   
    <b-checkbox class="common-font mr-5 no-print" disabled v-if="questionPaperLevelCalculationFailed">View Question Paper Level (<span style="color: red;"> Failed to calculate</span>)</b-checkbox>
   
    <!-- ---------------- -->
    <!-- graphs  ------------------------->
    <b-row class="mt-5 common-font" v-if="examSettings.distributionGraphField">
      <b-col class="col-6  text-center">
        <Bar :chart-data="coScoreDistributionChartData" :chart-options="chartOptions" />
      </b-col>
      <b-col class="col-6  text-center">
        <Pie :chart-data="taxonamyScoreDistributionChartData" :chart-options="chartOptions1" />
      </b-col>
    </b-row>
    <!-- -------------------------------- -->
  </div>
</template>

<script>

// to get in table format we need to loop in question order 
// and find out the enabled heading columns 
// and get the value of each column for each question 
// also the type of row wich is question/or group/ section 

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, ArcElement, CategoryScale, LinearScale)
import { Bar, Pie } from 'vue-chartjs/legacy'


import Vue from 'vue';
import * as MathLive from 'mathlive';
Vue.use(MathLive);
export default {
  props: {
    questionsAndGroupsAndSectionView: { type: Array, required: true },
    groupObjects: { type: Object, required: true },
    exam: { type: Object, required: true },
    sectionObjects: { type: Object, required: true },
    questionObjects: { type: Object, required: true },
    examSettings: { type: Object, required: true },
  },
  async created() {
    this.loading = true;
    this.cloudStorageRoot = await this.$store.getters.getAPIKey.userAttachments;
  },
  watch: {
    questionsAndGroupsAndSectionView: 'handlePropsLoaded',
    groupObjects: 'handlePropsLoaded',
    exam: 'handlePropsLoaded',
    sectionObjects: 'handlePropsLoaded',
  },
  async mounted() {
    this.handlePropsLoaded();
  },
  components: {
    Bar, Pie
  },
  data() {
    return {
      enableQuestionTableBorder: true,
      questionPaperTableData: [],
      questionAttachments: {},//id:{id, url, name}
      courseOutcomes: {},
      bloomTaxonomyOptions: [
        {
          value: "6",
          text: "Creating",
          description: "User information to create something new",
        },
        {
          value: "5",
          text: "Evaluating",
          description: "Examine information and make judgement.",
        },
        {
          value: "4",
          text: "Analyzing",
          description: "Take apart from known and identify relationships.",
        },
        {
          value: "3",
          text: "Applying",
          description: "Use information in a new (but similar) situation.",
        },
        {
          value: "2",
          text: "Understanding",
          description: "Grasp meaning of instructional materials.",
        },
        {
          value: "1",
          text: "Remembering",
          description: "Recall specific facts.",
        },
      ],
      loading: true,
      cloudStorageRoot: '',
      courseModules: [],
      customHeader: null,
      footerData: { course_admin: '', maingroup_admin: '' },
      coScoreDistributionChartData: {
        labels: [], datasets: [{
          label: 'CO Wise Marks Distribution',
          backgroundColor: '#334182',
          data: []
        }]
      },
      taxonamyScoreDistributionChartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16', '#7daa63', '#ffc107'],
            data: []
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      chartOptions1: {
        responsive: true,
        maintainAspectRatio: false
      },
      //Blooms Level Wise Marks Distribution
      taxonamyScoreSum: 0,
      taxonamyScoreProductSum: 0,
      questionPaperLevelCalculationFailed: false,
    };
  },
  methods: {
    handlePropsLoaded() {
      if (
        this.questionsAndGroupsAndSectionView &&
        this.groupObjects &&
        this.exam &&
        this.sectionObjects
      ) {
        // All required props are loaded, call your function
        this.prepareTemplateData();
      }
    },
    
    async prepareTemplateData() {
      await Promise.all([
        this.getCourseModules(),
        this.ExamQuestionPaperCustomHeader(),
      ]);

      this.questionPaperTableData = await this.prepareQuestionPaperTableData();
      // console.log(this.questionPaperTableData);

      this.loading = false;

      await MathLive.renderMathInDocument();

      for (const key in this.questionObjects) {
        const q = this.questionObjects[key];
        q.course_outcome.forEach(co => {
          this.courseOutcomes[co.id] = co;
        });
      }
      await this.getExamQuestionPaperFooter();
      this.prepareMarkDistributionGraph();
    },
    async prepareQuestionPaperTableData() {

      let questionsAndGroupsAndSectionView = await this.questionsAndGroupsAndSectionView;
      let groupObjects = await this.groupObjects;
      let questionObjects = await this.questionObjects;
      let sectionObjects = await this.sectionObjects;

      let questionPaperTableData = [];
      // this.enabledHeaders = {
      //   co: false, module: false, taxonamy: false, po: false, pso: false
      // }
       await Object.values(questionObjects).forEach(question => {
      //   if (question.blooms_taxonamy_level != null) this.enabledHeadersTaxonamy = true;
      //   if (question.module_id != null) this.enabledHeadersModule = true;
      //   if (question.course_outcome.length > 0) this.enabledHeadersCo = true;
      //   if (question.programme_outcome.length > 0) this.enabledHeadersPo = true;
      //  if (question.programme_specific_outcome.length > 0) this.enabledHeadersPso = true;
        question.files.forEach(file => {
          this.questionAttachments[file.id] = {
            id: file.id, name: file.file_name, url: this.cloudStorageRoot + file.file_path + file.file_name
          };
         });
       });
      let tempCustomHeaderCount = 0;
      if(this.examSettings.enabledHeadersCo) tempCustomHeaderCount++;
      if(this.examSettings.enabledHeadersModule) tempCustomHeaderCount++;
      if(this.examSettings.enabledHeadersTaxonamy) tempCustomHeaderCount++;
      if(this.examSettings.enabledHeadersPo) tempCustomHeaderCount++;
      if(this.examSettings.enabledHeadersPso) tempCustomHeaderCount++;

      let rowData = null;
      let taxonamyScoreProductSum = 0; // sum( taxonamy of q1 * score of q1 + ..q2...q3)
      let taxonamyScoreSum = 0; // sum of all question scores
      await questionsAndGroupsAndSectionView.forEach(ViewElement => {
        // console.log('-');
        if (ViewElement.type == 'group') {

          rowData = this.newRowDataSample();
          rowData.id = ViewElement.id;
          rowData.type = 'orGroup';
          rowData.colspan = tempCustomHeaderCount + 3;
          rowData.text = 'Answer ' + groupObjects[ViewElement.id].best_of_questions + ' out of ' + groupObjects[ViewElement.id].questions.length + ' questions';
          questionPaperTableData.push(rowData);

          let groupQuestionCount = groupObjects[ViewElement.id].questions.length;
          let groupQuestionCountIndex = groupQuestionCount;
          groupObjects[ViewElement.id].questions.forEach(question => {
            // console.log('--');
            rowData = this.newRowDataSample();
            rowData.id = question.id;
            rowData.type = 'question';
            rowData.q_no = question.question_no;
            rowData.question = question.question;
            if (question.subQuestions.length > 0) {
              rowData.colspan = tempCustomHeaderCount + 2;
            } else {
              rowData.mark = question.maximum_mark;
              rowData.co = question.course_outcome != null ? question.course_outcome.map(item => item.code).join(', ') : '';
              rowData.module = this.moduleName(question.module_id);
              rowData.taxonamy = question.blooms_taxonamy_level;
              rowData.po = question.programme_outcome != null ? question.programme_outcome.map(item => item.code).join(', ') : '';
              rowData.pso = question.programme_specific_outcome != null ? question.programme_specific_outcome.map(item => item.code).join(', ') : '';
              try {
                taxonamyScoreProductSum += Number(question.blooms_taxonamy_level) * Number(question.maximum_mark);
                taxonamyScoreSum += Number(question.maximum_mark);
              } catch (error) {
                this.questionPaperLevelCalculationFailed = true;
              }
            }
            questionPaperTableData.push(rowData);

            if (question.subQuestions.length > 0) {
              question.subQuestions.forEach(subQuestion => {
                // console.log('---');
                rowData = this.newRowDataSample();
                rowData.id = subQuestion.id;
                rowData.type = 'question';
                rowData.q_no = subQuestion.question_no;
                rowData.question = subQuestion.question;
                rowData.mark = subQuestion.maximum_mark;
                rowData.co = subQuestion.course_outcome != null ? subQuestion.course_outcome.map(item => item.code).join(', ') : '';
                rowData.module = this.moduleName(subQuestion.module_id);
                rowData.taxonamy = subQuestion.blooms_taxonamy_level;
                rowData.po = subQuestion.programme_outcome != null ? subQuestion.programme_outcome.map(item => item.code).join(', ') : '';
                rowData.pso = subQuestion.programme_specific_outcome != null ? subQuestion.programme_specific_outcome.map(item => item.code).join(', ') : '';
                questionPaperTableData.push(rowData);
                try {
                  taxonamyScoreProductSum += Number(subQuestion.blooms_taxonamy_level) * Number(subQuestion.maximum_mark);
                  taxonamyScoreSum += Number(subQuestion.maximum_mark);
                } catch (error) {
                  this.questionPaperLevelCalculationFailed = true;
                }
              });
            }

            if (groupQuestionCountIndex != 1) {
              rowData = this.newRowDataSample();
              rowData.id = Math.random().toString(36).substring(7);
              rowData.type = 'orGroupQuestionSeparation';
              rowData.colspan = tempCustomHeaderCount + 3;
              rowData.text = (groupQuestionCount == 2) ? 'OR' : '';  // separation text between or questions
              questionPaperTableData.push(rowData);
            }
            groupQuestionCountIndex--;

          });
        }
        if (ViewElement.type == 'question') {
          let question = questionObjects[ViewElement.id];
          rowData = this.newRowDataSample();
          rowData.id = ViewElement.id;
          rowData.type = 'question';
          rowData.q_no = question.question_no;
          rowData.question = question.question;
          if (question.subQuestions.length > 0) {
            rowData.colspan = tempCustomHeaderCount + 2;
          } else {
            rowData.mark = question.maximum_mark;
            rowData.co = question.course_outcome != null ? question.course_outcome.map(item => item.code).join(', ') : '';
            rowData.module = this.moduleName(question.module_id);
            rowData.taxonamy = question.blooms_taxonamy_level;
            rowData.po = question.programme_outcome != null ? question.programme_outcome.map(item => item.code).join(', ') : '';
            rowData.pso = question.programme_specific_outcome != null ? question.programme_specific_outcome.map(item => item.code).join(', ') : '';
            try {
              taxonamyScoreProductSum += Number(question.blooms_taxonamy_level) * Number(question.maximum_mark);
              taxonamyScoreSum += Number(question.maximum_mark);
            } catch (error) {
              this.questionPaperLevelCalculationFailed = true;
            }
          }
          questionPaperTableData.push(rowData);

          if (question.subQuestions.length > 0) {
            question.subQuestions.forEach(subQuestion => {
              // console.log('--');
              rowData = this.newRowDataSample();
              rowData.id = subQuestion.id;
              rowData.type = 'question';
              rowData.q_no = subQuestion.question_no;
              rowData.question = subQuestion.question;
              rowData.mark = subQuestion.maximum_mark;
              rowData.co = subQuestion.course_outcome != null ? subQuestion.course_outcome.map(item => item.code).join(', ') : '';
              rowData.module = this.moduleName(subQuestion.module_id);
              rowData.taxonamy = subQuestion.blooms_taxonamy_level;
              rowData.po = subQuestion.programme_outcome != null ? subQuestion.programme_outcome.map(item => item.code).join(', ') : '';
              rowData.pso = subQuestion.programme_specific_outcome != null ? subQuestion.programme_specific_outcome.map(item => item.code).join(', ') : '';
              questionPaperTableData.push(rowData);
              try {
                taxonamyScoreProductSum += Number(subQuestion.blooms_taxonamy_level) * Number(subQuestion.maximum_mark);
                taxonamyScoreSum += Number(subQuestion.maximum_mark);
              } catch (error) {
                this.questionPaperLevelCalculationFailed = true;
              }
            });
          }

        }
        if (ViewElement.type == 'section') {
          rowData = this.newRowDataSample();
          rowData.id = ViewElement.id;
          rowData.type = 'section';
          rowData.colspan = tempCustomHeaderCount + 3;
          rowData.text = sectionObjects[ViewElement.id].name;
          questionPaperTableData.push(rowData);
        }
      });
      this.footerQuestionPaperLevel = !this.questionPaperLevelCalculationFailed && taxonamyScoreProductSum > 0 && taxonamyScoreSum > 0;
      this.taxonamyScoreProductSum = taxonamyScoreProductSum;
      this.taxonamyScoreSum = taxonamyScoreSum;
      // console.log('- completed');
      return questionPaperTableData;
    },
    newRowDataSample() {
      return {
        id: null,
        type: null, //orGroup,orGroupQuestionSeparation,section,question
        colspan: null,
        text: null,
        q_no: null,
        question: null,
        mark: null,
        co: null,
        module: null,
        taxonamy: null,
        po: null,
        pso: null
      }
    },
    latexCodeToMathLive(codeText) {
      if (codeText == null || codeText == '') return '';
      return codeText.replace(/\$\$(.*?)\$\$/g, '<math-field class="p-0" style="border: none;" readonly>$1</math-field>');
    },
    async getExamQuestionPaperFooter() {
      const url =
        this.$store.getters.getAPIKey.examination +
        `/${this.exam.id}/questionpaper/footer`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.footerData = response.data;
        })
        .catch((error) => {
          error;
        });
    },
    prepareMarkDistributionGraph() {
      let taxonamyScoreDistribution = {};
      let coScoreDistribution = {};
      let processedOrQuestionGroupIds = [];
      for (const key in this.questionObjects) {
        const mainQuestion = this.questionObjects[key];

        let skipOrQuestion = false;
        if (mainQuestion.orquestion_group_id) {
          if (processedOrQuestionGroupIds.includes(mainQuestion.orquestion_group_id)) {
            skipOrQuestion = true
          } else {
            processedOrQuestionGroupIds.push(mainQuestion.orquestion_group_id);
          }
        }

        let tempQuestions = [mainQuestion];
        if (mainQuestion.subquestion_parent_id == null && !skipOrQuestion) {
          let subQuestions = Object.values(this.questionObjects).filter(q => q.subquestion_parent_id == mainQuestion.id);
          if (subQuestions.length > 0) tempQuestions = subQuestions;

          // let taxonomyLevel = null;
          // let cosId = null;
          // let qScore = 0;
          // tempQuestions.forEach(question => {
          //   qScore+=Number(question.maximum_mark);
          //   if (question.blooms_taxonamy_level != null && question.blooms_taxonamy_level != '') taxonomyLevel = question.blooms_taxonamy_level;
          //   if (question.course_outcome != null && question.course_outcome.length > 0) cosId = question.course_outcome[0].id;
          // });
          let qScore = tempQuestions.reduce((sum, question) => {
            return sum + Number(question.maximum_mark);
          }, 0);
          let taxonomyLevel = tempQuestions.find(question => question.blooms_taxonamy_level != null && question.blooms_taxonamy_level !== '')?.blooms_taxonamy_level || null;
          let cosId = tempQuestions.find(question => question.course_outcome != null && question.course_outcome.length > 0)?.course_outcome[0]?.id || null;

          // if (taxonomyLevel ) {
          //   if (!(taxonomyLevel in this.taxonamyScoreDistribution))
          //     this.taxonamyScoreDistribution[taxonomyLevel] = 0;
          //   this.taxonamyScoreDistribution[taxonomyLevel] +=qScore;
          // }
          // if (cosId) {
          //     if (!(cosId in this.coScoreDistribution)) this.coScoreDistribution[cosId] = 0;
          //     this.coScoreDistribution[cosId] += qScore;
          // }
          if (taxonomyLevel) {
            taxonamyScoreDistribution[taxonomyLevel] = (taxonamyScoreDistribution[taxonomyLevel] || 0) + qScore;
          }
          if (cosId) {
            coScoreDistribution[cosId] = (coScoreDistribution[cosId] || 0) + qScore;
          }
        }
      }
      this.coScoreDistributionChartData.labels = [];
      this.coScoreDistributionChartData.datasets[0].data = [];
      Object.values(this.courseOutcomes).forEach(co => {
        if (co.id in coScoreDistribution) {
          this.coScoreDistributionChartData.labels.push(co.code);
          this.coScoreDistributionChartData.datasets[0].data.push(coScoreDistribution[co.id]);
        }
      });
      this.taxonamyScoreDistributionChartData.labels = [];
      this.taxonamyScoreDistributionChartData.datasets[0].data = [];
      this.bloomTaxonomyOptions.forEach(tax => {
        if (tax.value in taxonamyScoreDistribution) {
          this.taxonamyScoreDistributionChartData.labels.push('L' + tax.value + '(' + taxonamyScoreDistribution[tax.value] + ')');
          this.taxonamyScoreDistributionChartData.datasets[0].data.push(taxonamyScoreDistribution[tax.value]);
        }
      });

      // console.log(coScoreDistribution);
      // console.log(taxonamyScoreDistribution);
    },
    // questionAttachmentDetails(attachmentId, question) {
    //   // console.log(question);
    //   return question.files.find(item => item.id == attachmentId);
    // },
    moduleName(id) {
      let module = this.courseModules.find(item => item.id === id);
      if (module) return module.module;
      return id;
    },
    async getCourseModules() {
      let courseModuleUrl = this.$store.getters.getAPIKey.getCourseModules;
      const url = courseModuleUrl.replace("course_id", this.exam.course_id);
      await this.$axios.get(url).then((response) => {
        this.courseModules = response.data;
      });
    },

    async ExamQuestionPaperCustomHeader() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/exams/${this.exam.id}/questionpaper/customheader`;
      await this.$axios.get(url).then((response) => {
        this.customHeader = response.data;
      });
    },
  },
};
</script>
<style scoped>
.no-border-cell td {
  border: none;
}

.no-border-cell th {
  border: none;
}

.no-border-cell tr {
  border: none;
}
</style>
<style>
.custom-table22 {
  width: 100%;
  font-weight: initial;
}

.custom-table22 td {
  vertical-align: top !important;
  padding: 2px 1px 1px 2px;
}
</style>
<style>
.questions-container button {
  padding: 1px !important;
}

@media print {
  .no-print {
    display: none !important;
  }

  .tempInput {
    border: none;
  }

  .questionPaperPreview {
    font-size: 14pt !important;
  }

  * {
    font-size: 14pt !important;
  }
 
}

.questionPaperPreviewImg {
  max-width: 100%;
  max-height: 600px;
}

.customHeaderDiv th {
  font-size: 12pt;
  background-color: #d7dcda;
}

.customHeaderDiv .data-tbody {
  font-size: 11pt;
  /* text-align: left; */
}

/* .data-table{
            table-layout: fixed;
            width: 660pt;
        } */
.customHeaderDiv .text-center {
  text-align: center;
}

.customHeaderDiv .header-table {
  font-size: 11pt !important;
  text-align: center;
}

.customHeaderDiv .header-table strong {
  font-size: 17pt !important;
  font-weight: 800;
}

.customHeaderDiv table {
  /* text-align: left; */
  width: 100%;
  border-collapse: collapse;
}

.customHeaderDiv table,
th,
td {
  border: 1px solid #494b4d;
}

.customHeaderDiv td {
  padding-bottom: 2pt;
  padding-top: 4pt;
  padding-left: 4pt;
  padding-right: 4pt;
}

.customHeaderDiv th {
  padding-bottom: 4pt;
  padding-top: 7pt;
  padding-left: 7pt;
  padding-right: 7pt;
}

.customHeaderDiv table tr td {
  height: auto;
}

.customHeaderDiv .wordbreak {
  word-wrap: break-word;
}

.common-font {
  font-family: 'Times New Roman';
  zoom: 1.4; /* Scales the text by 1.37 times */
}
</style>
